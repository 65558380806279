<template>
    <div :key="user.id" class="user-block">
        <header>
            <avatar :user="user" :live="is_active" />
            <span>{{ user.name }}</span>
            <span class="total">
                Total:
                <strong>{{ (total_duration * 1000) | milli2duration }}</strong>
                <span
                    v-if="
                        has_reported_hours_rounding &&
                        total_time_spent_reportable_for_user
                    "
                >
                    /
                    <strong class="reportable">
                        {{
                            total_time_spent_reportable_for_user
                                | milli2duration
                        }}
                    </strong>
                </span>
            </span>
        </header>
        <ul>
            <session-activity-block
                v-for="session in sessions"
                :key="session.id"
                :session="session"
                :in_group="false"
                :stop="session.stop"
            />
        </ul>
    </div>
</template>

<script>
import firebase from 'firebase/app';
import dayjs from 'dayjs';

import SessionActivityBlock from '@/components/blocks/SessionActivityBlock';
import Avatar from '@/components/generic/Avatar';

import componentMixin from '@/mixins/component.mixin';

export default {
    name: 'user-activity-block',
    components: {
        SessionActivityBlock,
        Avatar,
    },
    mixins: [componentMixin],
    props: {
        user_id: {
            type: String,
            required: true,
        },
        sessions: {
            type: Array,
            required: true,
        },
    },
    computed: {
        user() {
            return this.$store.getters.userWithId(this.user_id);
        },
        is_active() {
            const activeSession = this.sessions.find((s) => s.live || !s.stop);
            return !!activeSession;
        },
        total_duration() {
            return this.sessions
                .map((s) => {
                    if (s.stop) {
                        return s.stop.seconds - s.start.seconds;
                    } else {
                        //Session is active
                        return new Date().getTime() / 1000 - s.start.seconds;
                    }
                })
                .reduce((a, b) => a + b, 0);
        },
        total_time_spent_reportable_for_user() {
            if (!this.component.time_reportable) return null;
            // grab date from first session
            const date = dayjs(this.sessions[0].start.toDate());
            const dateKey = date.format('YYYY-MM-DD');
            return this.component.time_reportable[dateKey]?.[this.user_id];
        },
        component() {
            return this.$store.getters.componentWithId(
                this.$route.params.component_id
            );
        },
    },
    methods: {
        toTimestamp(date) {
            if (!date) return null;
            return new firebase.firestore.Timestamp(date.getTime() / 1000, 0);
        },
    },
};
</script>

<style lang="scss" scoped>
.user-block {
    &:first-of-type header {
        padding-top: 5px;
    }

    header {
        padding: 20px;
        margin-bottom: 20px;
        border-bottom: 2px solid $border-grey-light;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        .avatar-wrapper {
            margin-right: 10px;
        }

        .total {
            flex: 1;
            text-align: right;
        }
    }

    ul {
        padding: 0;
        list-style: none;
    }

    ul.group {
        &:hover ::v-deep {
            .start-stop.real,
            .duration.real {
                display: inline-block;
                opacity: 1;
            }

            .duration.rounded,
            .start-stop.rounded {
                display: none;
                opacity: 0;
            }
        }

        li.child {
            pointer-events: none;
            overflow: hidden;
        }

        li.child:not(.has-note) {
            display: block;
            height: 0px;
            opacity: 0;
            transition: all 0.3s ease;
            padding-top: 0;
            padding-bottom: 0;
            margin-bottom: 0;
        }

        &:hover {
            li.child:not(.has-note) {
                display: flex;
                height: 40px;
                opacity: 1;
                padding-top: 10px;
                padding-bottom: 10px;
                margin-bottom: 5px;
            }
        }
    }

    .group-transition-enter-active {
        transition: all 0.3s ease;
    }
    .group-transition-leave-active {
        transition: all 0.3s ease;
    }
    .group-transition-enter,
    .group-transition-leave-to {
        opacity: 0;
    }

    .reportable {
        color: $blue;
    }
}
</style>
