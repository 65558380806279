<template>
    <div>
        <section>
            <el-row :gutter="12">
                <!--<el-col :sm="24" :md="12" :lg="6">-->
                <!--    <widget-card :loading="loading">-->
                <!--        <div class="key-value">-->
                <!--            <label>Linked Epics</label>-->
                <!--            <span>-->
                <!--                {{ num_epics }}-->
                <!--            </span>-->
                <!--        </div>-->
                <!--    </widget-card>-->
                <!--</el-col>-->
                <el-col :sm="24" :md="12" :lg="6">
                    <widget-card :loading="loading">
                        <div class="key-value">
                            <label>Tasks</label>
                            <span> {{ num_tasks }} / {{ num_subtasks }} </span>
                        </div>
                    </widget-card>
                </el-col>
                <el-col :sm="24" :md="12" :lg="6">
                    <widget-card :loading="loading">
                        <div class="filters">
                            <span>Users</span>
                            <div class="users">
                                <template v-if="!filter_users.length">
                                    <div
                                        v-for="(_, idx) in Array(6).fill()"
                                        :key="idx"
                                        class="avatar"
                                    />
                                    <div class="overlay" />
                                </template>
                                <template v-else>
                                    <div
                                        v-for="obj in filter_users"
                                        :key="obj.user.id"
                                        class="avatar"
                                        :class="obj.status"
                                        @click="toggleUser(obj)"
                                    >
                                        <avatar :user="obj.user" />
                                    </div>
                                </template>
                            </div>
                        </div>
                    </widget-card>
                </el-col>
                <el-col :sm="24" :md="12" :lg="6">
                    <widget-card class="total-hours" :loading="loading">
                        <div class="key-value">
                            <label>Estimated hours</label>
                            <span>
                                {{ total_time_estimated | milli2duration }}
                            </span>
                        </div>
                    </widget-card>
                </el-col>
                <el-col :sm="24" :md="12" :lg="6">
                    <widget-card
                        v-if="has_reported_hours_rounding"
                        class="total-hours"
                        :loading="loading"
                    >
                        <div class="key-value">
                            <label>Time spent / reportable hours</label>
                            <span>
                                {{ total_time_spent | seconds2duration }} /
                                <b>
                                    {{
                                        total_time_spent_reportable
                                            | milli2duration
                                    }}
                                </b>
                            </span>
                        </div>
                    </widget-card>
                    <widget-card v-else class="total-hours" :loading="loading">
                        <div class="key-value">
                            <label>Time spent</label>
                            <span>
                                {{ total_time_spent | seconds2duration }}
                            </span>
                        </div>
                    </widget-card>
                </el-col>
            </el-row>
        </section>
        <section>
            <div class="groups">
                <accordion
                    v-for="group in mapped_data"
                    :key="group.id"
                    class="epic-accordion"
                >
                    <template #header="{is_expanded}">
                        <component-tracked-issue
                            :title="group.title"
                            issue_category="Epic"
                            :linked_issue="group.issue"
                            :time_spent="group.time_spent"
                            :time_estimate="group.time_estimate"
                            :show_column_labels="is_expanded"
                            :start_date="group.start_date"
                            :end_date="group.end_date"
                            can_bind_issue
                            @bind="(issue) => bindIssue(group, issue)"
                            @unbind="() => unbindIssue(group)"
                        />
                    </template>

                    <div class="task-container">
                        <accordion v-for="task in group.tasks" :key="task.id">
                            <template #header>
                                <component-tracked-issue
                                    :title="task.description"
                                    :issue_category="task.jira_category"
                                    :linked_issue="issueFor(task.linked_issue)"
                                    :time_spent="task.time_spent"
                                    :time_estimate="task.time_estimate"
                                    :start_date="task.start_date"
                                    :end_date="task.end_date"
                                    can_bind_issue
                                    @bind="(issue) => bindIssue(task, issue)"
                                    @unbind="() => unbindIssue(task)"
                                />
                            </template>

                            <div
                                class="subtask"
                                v-for="subtask in task.subtasks"
                                :key="subtask.id"
                            >
                                <component-tracked-issue
                                    :title="subtask.description"
                                    :issue_category="subtask.jira_category"
                                    :linked_issue="subtask.issue"
                                    :time_spent="subtask.time_spent"
                                    :time_estimate="subtask.time_estimate"
                                    :start_date="subtask.start_date"
                                    :end_date="subtask.end_date"
                                    can_bind_issue
                                    @bind="(issue) => bindIssue(subtask, issue)"
                                    @unbind="() => unbindIssue(subtask)"
                                />
                            </div>
                        </accordion>
                    </div>
                </accordion>

                <template v-if="issues_to_sessions[UNMATCHED_SESSIONS]?.length">
                    <accordion class="epic-accordion">
                        <template #header="{is_expanded}">
                            <component-tracked-issue
                                title="Unmatched Sessions"
                                issue_category="Epic"
                                :linked_issue="null"
                                :time_spent="total_untracked_time_spent"
                                :show_column_labels="is_expanded"
                            />
                        </template>
                        <div
                            class="unmatched-session"
                            v-for="session in issues_to_sessions[
                                UNMATCHED_SESSIONS
                            ]"
                            :key="session.id"
                        >
                            <component-tracked-issue
                                :session="session"
                                :title="session.note || ''"
                                :user_id="session.user"
                                :time_spent="time_spent_on_session(session)"
                                :linked_issue="null"
                            />
                        </div>
                    </accordion>
                </template>
            </div>
        </section>
    </div>
</template>

<script>
import WidgetCard from '@/components/generic/WidgetCard';
import Avatar from '@/components/generic/Avatar';

import componentSessionMixin from '@/mixins/component.session.mixin';
import componentMixin from '@/mixins/component.mixin';

import ComponentTrackedIssue from '@/pages/components/components/ComponentTrackedIssue.vue';

import {Notification} from 'element-ui';
import {fireRef2id, seconds2hours} from '@/filters';
import Accordion from '@/components/generic/Accordion.vue';
import bindMixin from '@/mixins/bind.mixin';
import firebase from 'firebase/app';
import estimateMixin from '@/mixins/estimate.mixin';

export default {
    name: 'component-tab-overview',
    components: {
        ComponentTrackedIssue,
        Accordion,
        WidgetCard,
        Avatar,
    },
    mixins: [componentSessionMixin, componentMixin, estimateMixin, bindMixin],
    data() {
        return {
            sessions: [],
            estimates: {},
            groups: [],
            tasks: [],
            subtasks: [],
            filter_users: [],
            loading: true,
        };
    },
    computed: {
        UNMATCHED_SESSIONS() {
            return 'Unmatched sessions';
        },
        current_time() {
            if (this.has_active_sessions) {
                return this.$store.state.current_time;
            }
            return new Date().getTime();
        },
        component_id() {
            return this.$route.params.component_id;
        },
        component() {
            return this.$store.getters.componentWithId(this.component_id);
        },
        involved_users() {
            return [
                ...new Set(
                    this.sessions.map((s) => {
                        return s.user;
                    })
                ),
            ].map((user_ref) => {
                return this.$store.getters.userWithId(fireRef2id(user_ref));
            });
        },
        tasks_linked() {
            if (!this.tasks?.length) return [];
            return this.tasks?.map((task) => {
                // tasks -> subtasks
                const task_subtasks = this.subtasks?.filter((subtask) => {
                    return fireRef2id(subtask.task) === task.id;
                });
                return {
                    ...task,
                    id: task.id,
                    subtasks: task_subtasks,
                };
            });
        },
        groups_linked() {
            if (!this.groups?.length) return [];
            // groups -> tasks
            return this.groups?.map((group) => {
                const group_tasks = this.tasks_linked?.filter((task) => {
                    return fireRef2id(task.group) === group.id;
                });
                return {
                    ...group,
                    id: group.id,
                    path: group.path,
                    tasks: group_tasks,
                };
            });
        },
        filtered_groups() {
            return (
                this.groups_linked?.filter((group) => {
                    return !this.isGroupExcluded(group, group.tasks);
                }) || []
            );
        },
        filtered_tasks() {
            return (
                this.tasks_linked?.filter((task) => {
                    const group_id = fireRef2id(task.group.id);
                    const group = this.filtered_groups.find((group) => {
                        return group.id === group_id;
                    });
                    return (
                        group &&
                        !group.excluded &&
                        !this.isTaskExcluded(task, task.subtasks)
                    );
                }) || []
            );
        },
        filtered_subtasks() {
            return (
                this.subtasks?.filter((subtask) => {
                    const task_id = fireRef2id(subtask.task.id);
                    const found_task = this.filtered_tasks.find(
                        (task) => task.id === task_id
                    );
                    return (
                        found_task && !found_task.excluded && !subtask.excluded
                    );
                }) || []
            );
        },
        has_active_sessions() {
            return this.sessions.some((session) => {
                return !session.stop;
            });
        },
        num_epics() {
            return this.filtered_groups?.length;
        },
        num_tasks() {
            return this.filtered_tasks?.length;
        },
        num_subtasks() {
            return this.filtered_subtasks?.length;
        },
        total_time_estimated() {
            return this.component.time_estimate;
        },
        ids_to_issues() {
            return [
                ...this.filtered_groups,
                ...this.filtered_tasks,
                ...this.filtered_subtasks,
            ].reduce((acc, record) => {
                if (record.linked_issue) {
                    const ref_id =
                        record.linked_issue?.path ?? record.linked_issue;
                    acc[ref_id] = this.$store.getters.issueWithDocumentId(
                        fireRef2id(ref_id)
                    );
                }
                return acc;
            }, {});
        },
        total_time_spent() {
            return this.filtered_sessions
                .map((s) => {
                    if (s.stop) {
                        return s.stop.seconds - s.start.seconds;
                    } else {
                        //Session is active
                        return this.current_time / 1000 - s.start.seconds;
                    }
                })
                .reduce((a, b) => a + b, 0);
        },
        total_time_spent_reportable() {
            if (!this.component.time_reportable) return 0;
            return Object.keys(this.component.time_reportable).reduce(
                (acc, day) => {
                    return (
                        acc +
                        Object.values(
                            this.component.time_reportable[day]
                        ).reduce((a, t) => {
                            return a + t;
                        }, 0)
                    );
                },
                0
            );
        },
        total_untracked_time_spent() {
            return this.issues_to_sessions[this.UNMATCHED_SESSIONS].reduce(
                (acc, session) => {
                    return acc + this.time_spent_on_session(session);
                },
                0
            );
        },
        filtered_sessions() {
            return this.filter_users
                .map((obj) => {
                    if (obj.status === 'active') {
                        return this.sessions.filter((s) => {
                            // skip sessions that resolve to under 1 minute
                            if (s.stop && s.stop.seconds - s.start.seconds < 60)
                                return false;

                            return s.user === obj.user.path;
                        });
                    }
                })
                .filter((x) => !!x)
                .flat();
        },
        mapped_data() {
            if (
                !this.filtered_groups?.length ||
                !Object.values(this.estimates).length
            )
                return [];
            // groups -> tasks
            return this.filtered_groups
                ?.map((group) => {
                    const est = this.estimates[group.estimate];
                    const to_hours_multiplier =
                        est.unit === 'day' ? est.hours_per_day : 1;
                    const group_tasks = this.filtered_tasks
                        ?.filter((task) => {
                            return fireRef2id(task.group) === group.id;
                        })
                        .map((task) => {
                            // tasks -> subtasks
                            const task_subtasks = this.filtered_subtasks
                                ?.filter((subtask) => {
                                    return fireRef2id(subtask.task) === task.id;
                                })
                                .map((subtask) => {
                                    const linked =
                                        subtask.linked_issue?.path ??
                                        subtask.linked_issue;
                                    return {
                                        ...subtask,
                                        issue: this.ids_to_issues[linked],
                                        time_spent: this.time_spent_on_ticket(
                                            linked
                                        ),
                                        time_estimate:
                                            (subtask.override?.qty ??
                                                subtask.qty) *
                                            to_hours_multiplier,
                                        start_date: this.ticket_start_seconds(
                                            linked
                                        ),
                                        end_date: this.ticket_end_seconds(
                                            linked
                                        ),
                                    };
                                })
                                .sort((a, b) => a.sort - b.sort);

                            const linked =
                                task.linked_issue?.path ?? task.linked_issue;
                            const time_spent_on_subtasks = task_subtasks.reduce(
                                (acc, subtask) => {
                                    return acc + subtask.time_spent;
                                },
                                0
                            );
                            return {
                                ...task,
                                subtasks: task_subtasks,
                                issue: this.ids_to_issues[linked],
                                time_spent:
                                    this.time_spent_on_ticket(linked) +
                                    time_spent_on_subtasks,
                                time_estimate:
                                    (task.override?.qty ?? task.qty) *
                                    to_hours_multiplier,
                                start_date: this.ticket_start_seconds(linked),
                                end_date: this.ticket_end_seconds(linked),
                            };
                        })
                        .sort((a, b) => a.sort - b.sort);
                    const linked =
                        group.linked_issue?.path ?? group.linked_issue;
                    const time_spent_on_tasks = group_tasks.reduce(
                        (acc, task) => {
                            return acc + task.time_spent;
                        },
                        0
                    );
                    return {
                        ...group,
                        tasks: group_tasks,
                        issue: this.ids_to_issues[linked],
                        time_spent:
                            this.time_spent_on_ticket(linked) +
                            time_spent_on_tasks,
                        time_estimate:
                            (group.override?.qty ?? group.qty) *
                            to_hours_multiplier,
                        start_date: this.ticket_start_seconds(linked),
                        end_date: this.ticket_end_seconds(linked),
                    };
                })
                .sort((a, b) => a.sort - b.sort);
        },
        issues_to_sessions() {
            // [{issue_id: [sessions]}]
            const sessions = [...(this.filtered_sessions ?? [])].sort(
                (a, b) => b.start.toDate() - a.start.toDate()
            );
            return (
                sessions.reduce(
                    (acc, session) => {
                        let matched = false;
                        if (session.bound_tasks?.length) {
                            session.bound_tasks.forEach((task) => {
                                if (task && this.ids_to_issues[task]) {
                                    if (!acc[task]) acc[task] = [];
                                    acc[task].push(session);
                                    matched = true;
                                }
                            });
                        }
                        if (!matched) {
                            acc[this.UNMATCHED_SESSIONS].push(session);
                        }
                        return acc;
                    },
                    {[this.UNMATCHED_SESSIONS]: []}
                ) || {[this.UNMATCHED_SESSIONS]: []}
            );
        },
    },
    watch: {
        involved_users(val) {
            this.filter_users = val.map((user) => {
                return {
                    user,
                    status: 'active',
                };
            });
        },
        component_id: {
            immediate: true,
            async handler(val, oldVal) {
                if (val && val === oldVal) return;
                this.$bind(
                    'groups',
                    this.$fire
                        .collection('estimate_groups')
                        .where(
                            'ref_links.tracking_component',
                            '==',
                            this.$fire.doc(`components/${val}`)
                        ),
                    {maxRefDepth: 0}
                );
                this.$bind(
                    'sessions',
                    this.$fire
                        .collection('sessions')
                        .where(
                            'component',
                            '==',
                            this.$fire.doc(`components/${val}`)
                        ),
                    {maxRefDepth: 0}
                );
            },
        },
        async groups(val) {
            await this.bindTasks(val);
            //note this could probably be restructured to be more optimal
            this.estimates = await val.reduce(async (acc, group) => {
                if (!acc[group.estimate]) {
                    acc[group.estimate] = await this.$fire
                        .doc(group.estimate)
                        .get()
                        .then((snapshot) => snapshot.data());
                }
                return acc;
            }, {});
        },
        async tasks(val) {
            await this.bindSubtasks(val);
            this.loading = false;
        },
    },
    methods: {
        seconds2hours,
        Notification,
        toggleUser(obj) {
            obj.status = obj.status === 'active' ? 'inactive' : 'active';
        },
        time_spent_on_session(s) {
            if (s.stop) {
                return s.stop.seconds - s.start.seconds;
            } else {
                //Session is active
                return this.current_time / 1000 - s.start.seconds;
            }
        },
        time_spent_on_ticket(ticket_path) {
            const sessions = this.issues_to_sessions[ticket_path];
            return (
                sessions
                    ?.map((s) => {
                        const num_tasks_in_session = s.bound_tasks?.length ?? 1;
                        return (
                            this.time_spent_on_session(s) / num_tasks_in_session
                        );
                    })
                    .reduce((a, b) => a + b, 0) || 0
            );
        },
        ticket_start_seconds(ticket_path) {
            const sessions = this.issues_to_sessions[ticket_path];
            if (!sessions || !sessions.length) return null;
            let best = sessions[0].start.seconds;
            for (const session of sessions) {
                if (session.start.seconds < best) {
                    best = session.start.seconds;
                }
            }
            return best;
        },
        ticket_end_seconds(ticket_path) {
            const sessions = this.issues_to_sessions[ticket_path];
            if (!sessions || !sessions.length) return null;
            let best = sessions[0].stop?.seconds;

            for (const session of sessions) {
                if (!session.stop) {
                    //still working on ticket - no end date - exit
                    return null;
                }
                if (session.stop.seconds > best) {
                    best = session.stop.seconds;
                }
            }
            return best;
        },
        bindIssue(target, issue) {
            this.$fire.doc(target.path).update({
                linked_issue: this.$fire.doc(issue),
            });
        },
        unbindIssue(target) {
            this.$fire.doc(target.path).update({
                linked_issue: firebase.firestore.FieldValue.delete(),
            });
        },
        async bindTasks(groups) {
            this.unbindFor('tasks');
            groups.map((group) => {
                this.bindDynamicSnapshot(
                    'tasks',
                    this.$fire
                        .collection('estimate_tasks')
                        .where(
                            'group',
                            '==',
                            this.$fire.doc(`estimate_groups/${group.id}`)
                        )
                );
            });
        },
        async bindSubtasks(tasks) {
            this.unbindFor('subtasks');
            if (tasks.length) {
                tasks.map((task) => {
                    this.bindDynamicSnapshot(
                        'subtasks',
                        this.$fire
                            .collection('estimate_subtasks')
                            .where(
                                'task',
                                '==',
                                this.$fire.doc(`estimate_tasks/${task.id}`)
                            )
                    );
                });
            }
        },
        issueFor(linked_issue) {
            if (linked_issue) {
                const id = linked_issue?.path ?? linked_issue;
                return this.ids_to_issues[id];
            }
            return null;
        },
    },
};
</script>

<style lang="scss" scoped>
section {
    margin: 0 0 10px 0;
    padding: 20px;
    border-radius: 5px;
    background-color: $grey;
}

.filters {
    flex: 1;
    display: flex;
    flex-direction: column;

    span {
        pointer-events: none;
        display: block;
        text-align: center;
        font-size: 12px;
        color: rgba($black, 0.5);
        margin-bottom: 10px;
    }
}

.groups {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.epic-accordion {
    margin-top: 10px;
}

.task-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.users {
    display: flex;
    flex-direction: row;

    .overlay {
        position: absolute;
        width: 200px;
        height: 24px;
        background: linear-gradient(90deg, rgba($white, 0) 0%, $white 100%);
    }

    .avatar {
        margin: 0 2.5px;
        height: 24px;
        width: 24px;
        border-radius: 12px;
        background-color: $border-grey-light;

        &.inactive {
            -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
            opacity: 0.7;
        }
    }
}

.total-hours {
    &:hover {
        cursor: pointer;
    }

    b {
        color: $blue;
        font-weight: 400;
    }
}

.subtask {
    border-radius: 5px;
    background-color: $grey;
    margin: 0 0 10px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.unmatched-session {
    padding-bottom: 10px;
}
</style>
